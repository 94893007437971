body {
  background-color: black;
  padding-top: 50px;
}

img {
  width: 100%;
}

.logo {
 padding-top: 2em;
 padding-left: 8em;
 padding-right: 8em;
 padding-bottom: 1em;
}

.logoicon {
  width: 70px;
  position: absolute;
  top: 6px;
  right: 6px;
}

p {
  color:white;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  font-size: 10pt;
  text-align: center; 
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  color:white;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  font-size: 11pt;
  font-weight: 600;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin: 0;
  text-align: left;
}

h3 {
  color:white;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  font-size: 10pt;
  font-weight: 600;
  margin: 0;
  text-align: center;
  padding-top: 1.5em;
  padding-bottom: 0em;
}

h4 {
  color:white;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  font-size: 10pt;
  font-weight: 600;
  margin: 0;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 0em;
}

h5 {
  color:white;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  font-size: 10pt;
  font-weight: 400;
  margin: 0;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2em;
}

a {
  color: white;
  text-decoration: none; /* no underline */
}

.mainTitle {
  color: white;
  text-align: center;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
}

.main-column {
  margin: auto;
  max-width: 50em;
  min-width: 320px;
}

.sticky-topnav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.sticky-topnav-column {
  margin: auto;
  width: 100%;
  min-width: 320px;
  --angle: 0deg;
	border-bottom: 2px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 20s rotate linear infinite;
  background-color: black;
}

.subnav {
  display: flex;
  flex-direction: column;
  z-index: 9998;
  background-color: black;
  --angle: 0deg;
	border: 1px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 20s rotate linear infinite;
  text-align: left;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: black;
	border: 1px solid;
  border-top: 0px;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 20s rotate linear infinite;
  padding: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -0.15em;
  text-align: left;
}

.sub-menu a {
  text-align: left;
}

.featured-card {
  align-content: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
  padding-bottom: 0;
}

.flexColumn {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: flex-start;
}

.subindexContainer {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.threeColumn {
  float: left;
  width: 33%;
  margin-top: 10px;    
  margin-bottom: 10px;    
}

.fourColumn {
  float: left;
  width: 25%;  
  margin-top: 10px;    
  margin-bottom: 10px;      
}

.centeredRow {
  text-align: center; 
}

.cardContainer {
  border-style: solid;
  border-color: darkslategray;
  border-width: 1px;
  margin: 1em;
} 

.cardContainerRed {
  border-style: solid;
  border-color: rgb(92, 0, 0);
  border-width: 1px;
  margin: 1em;
} 

.cardContainerOrange {
  border-style: solid;
  border-color: rgb(105, 47, 0);
  border-width: 1px;
  margin: 1em;
} 

.cardContainerYellow {
  border-style: solid;
  border-color: rgb(105, 89, 0);
  border-width: 1px;
  margin: 1em;
} 

.cardContainerGreen {
  border-style: solid;
  border-color: rgb(18, 105, 0);
  border-width: 1px;
  margin: 1em;
} 

.cardContainerBlue {
  border-style: solid;
  border-color: rgb(0, 105, 105);
  border-width: 1px;
  margin: 1em;
} 

.cardContainerPurple {
  border-style: solid;
  border-color: rgb(105, 0, 105);
  border-width: 1px;
  margin: 1em;
} 

.sticky-container {
  margin-top:50px;
}

.card-container-rainbow {
	--angle: 0deg;
	border: 1px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 20s rotate linear infinite;
  margin: 1.5em;
  padding: 0.5em;
} 

.card-container {
	--angle: 0deg;
	border: 0px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 20s rotate linear infinite;
  margin: 0.5em;
  margin-bottom: 2em;
} 

.title-wrap-rainbow {
	--angle: 0deg;
	border-bottom: 1px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 4s rotate linear infinite;
  margin-left: 1em;
  margin-right: 1em;
  position: sticky;
  top: 2.35em;
  background-color: black;
  z-index: 900;
} 

.title-wrap-rainbow2 {
	--angle: 0deg;
	border-bottom: 1px solid;
	border-image: conic-gradient(from var(--angle), magenta, red, yellow, lime, aqua, blue, magenta) 1;
	animation: 4s rotate linear infinite;
  margin-left: 1em;
  margin-right: 1em;
  position: sticky;
  top: 2.35em;
  background-color: black;
  z-index: 9000;
} 

.title-wrap-rainbow-full {
	--angle: 0deg;
	border-bottom: 1px solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 10s rotate linear infinite;
} 

.title-wrap-rainbow-dot {
	--angle: 0deg;
	border-bottom: 1px dotted;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	animation: 10s rotate linear infinite;
  margin-left: 1em;
  margin-right: 1em;
} 

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.containedText { 
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
  padding-bottom: 0;
}

.react-player-responsive { 
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 0;
  height: 24em;
}

.react-player-responsive-playlist { 
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 0;
  height: 40em;
}

.flex-column-contained-text { 
  align-content: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
}

.titleWrapPurple {
  border-bottom: 1px solid rgb(105, 0, 105);
}

.titleWrapRed {
  border-bottom: 1px solid rgb(92, 0, 0);
}

.titleWrapOrange {
  border-bottom: 1px solid rgb(105, 47, 0);
}

.titleWrapGreen {
  border-bottom: 1px solid rgb(18, 105, 0);
}

.titleWrapBlue {
  border-bottom: 1px solid rgb(0, 105, 105);
}

.titleWrapYellow {
  border-bottom: 1px solid rgb(105, 89, 0);
}

.testBox {
  border: 1px solid #2f4d0c;
    color: #2f4d0c;
    margin: 1em;
    background-color: white;
}

.gallery_containerThree {
  display: grid;
  justify-items: auto;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 0.5em;
  padding-top:2em;
  width: auto;
  gap: 0.5em;
  margin-right: 1em;
}

.gallery_containerThree-static {
  display: grid;
  justify-items: auto;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding: 1em;
  width: auto;
  gap: 0.5em;
  margin-right: 1em;
}

.StaticAlbumContainer {
  display: grid;
  justify-items: auto;
  grid-template-columns: 33.33% 33.33% 33.33%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 1.5em;
  width: auto;
  gap: 0.5em;
  margin-right: 1em;
}

.gallery_containerTwo {
  display: grid;
  justify-items: auto;
  grid-template-columns: 50% 50%;
  padding-left: 1em;
  padding-right: 1em; 
  width: auto;
  gap: 0.75em;
}

.gallery_item {
  object-fit: cover;
  width: auto;
  padding: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.masonry-text {
  margin-top: 0px;
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding-left: 16px;
}

.music-video {
  margin: 1em;
  z-index: 1;
}

.video-title-text {
  margin-top: 0.5em;
  padding-bottom: 1em;
}

.music-video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
  z-index: 1;
  border-style: none;
}

.music-video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  border-style: none;
}

.changelogtext {
  text-align: left;
}

.left-aligned {
  text-align: left;
}

.gallery-text {
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  font-size: 9pt;
}

.gallery-text-left {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 9pt;
  text-align: left;
}

.gallery-tag {
  margin-top: 0;
  margin-bottom: 0.2em;
  text-align: left;
  font-size: 8pt;
}

.gallery-tag-centered {
  margin-top: 0;
  margin-bottom: 0.2em;
  text-align: center;
  font-size: 8pt;
}

@media only screen and (max-width: 600px){
  .masonry-text {
    font-size: xx-small;
  }
  .gallery-text {
    font-size: xx-small;
  }
  .gallery-text-left {
    font-size: xx-small;
  }
  .gallery-tag {
    font-size: xx-small;
  } 
  .logo {
    padding-left: 3em;
    padding-right: 3em;
  } 
  .react-player-responsive {
    height: 12em;
  }
  .gallery_containerThree-static {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .title-wrap-rainbow {
    margin-left: 0.5em;
    margin-right: 0.5em;
    top: 2.2em;
  }
  .title-wrap-rainbow2 {
    margin-left: 0.5em;
    margin-right: 0.5em;
    top: 2.2em;
  }
  .card-container-rainbow {
    margin: 0.85em;
  }
  .masonry-wrap {
    margin: 0.5em!important;
  }
  .containedTextLeft {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0.6em!important;
    padding-right: 0.6em!important;
    text-align: left;
  }
  .containedImg {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .overflow-hidden {
    margin-bottom: 0px!important;
  }
}

.masonry-wrap {
  margin: 1em;
}

.spacer {
  margin-top: 1em;
}

.sticky {
  position: sticky;
  top: 36px;
  background-color: black;
  z-index: 6;
}

/*test zone*/

/* Add a black background color to the top navigation */
.topnav {
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;   
  color: #f2f2f2;
  text-align: left;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 1em;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #f2f2f2;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: black;
  color: magenta;
}

/* Add a black background color to the top navigation */
.topnavhomebutton {
  background-color: black;
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
}

/* Style the links inside the navigation bar */
.topnavhomebutton a {
  float: left;   
  color: #f2f2f2;
  text-align: left;
  padding: 10px 10px;
  padding-bottom: 8px;
  text-decoration: none;
  font-size: 0.78em;
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */

}

/* Change the color of links on hover */
.topnavhomebutton a:hover {
  background-color: black;
  color: magenta;
}

/* Add a color to the active/current link */
.topnavhomebutton a.active {
  background-color: black;
  color: magenta;
}

.activeLink {
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  color: white;
  font-size: 10pt;
  padding-top: 0px;
  padding-bottom: 0px;
}

.subindexFont {
  color: white;
}

.footerFont1 {
  color: #7d7d7d;
}

.subindexFont a.active {
  background-color: black;
  color: magenta;
}

.activeLink a {
  margin-left: 8px;
  margin-right: 8px;
}

/* Add a color to the active/current link */
.activeLink a.active {
  background-color: black;
  color: magenta;
}

.indexText {
  /* font-family: "ãƒ’ãƒ©ã‚®ãƒŽè§’ã‚´ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, ãƒ¡ã‚¤ãƒªã‚ª, Meiryo, "MS Pã‚´ã‚·ãƒƒã‚¯", "MS PGothic", farial, helvetica, clean, sans-serif; */
  color: white;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.indexText a {
  margin-left: 8px;
  margin-right: 8px;
}

/* Add a color to the active/current link */
.indexText a.active {
  background-color: black;
  color: magenta;
}

.containedImg {
  margin-left: 1em;
  margin-right: 1em;
}

.containedTextLeft {
  margin-left: .25em;
  margin-right: .25em;
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
  padding-bottom: 0;
}

.containedTextRight {
  margin-left: .25em;
  margin-right: .25em;
  text-align: right;
}

.grayTextLeft {
  color: #acacac;
  text-align: left;
}

.grayText {
  color: #acacac;
}

.backToTop {
  color: #555555;
  font-size: 12px;
  text-align: right;
}

.halfWidth {
  width: 50%;
  align-items: center!important;
}

.default {
  font-style: default;
}

/* community page */

.chat-main-column-community {
  height: 700px;
  display: flex;
  justify-content: center;
}

.chat-iframe-container-community {
  width: 100%;
  height: 100%;
  border: 0;
  max-width: 47em;
  max-height: 700px;
  padding-top: 20px;
  overflow: hidden!important;
  margin-left: 1em;
  margin-right: 1em;
}

.community-icons-wrapper {
  display:flex;
  align-items: center;
  justify-content: center;
}

.community-icons {
  width:86px;
  height:auto;
}

@media only screen and (max-width: 600px){
  .chat-iframe-container-community {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

/* chat page  */

.chat-main-column {
  height: 66vh;
  display: flex;
  justify-content: center;
}

.chat-iframe-container {
  width: 100%;
  height: 100%;
  border: 0;
  max-width: 50em;
  max-height: 700px;
  padding-top: 20px;
  overflow: hidden!important;
}

.chat-iframe-container::-webkit-scrollbar {
  display: none!important;
}

.cell {
  display:none;
}

.svgBack {
  display:none;
}

.smline {
  display:none;
}

/* Hover zoom */

.hover-zoom {
  transition: transform .22s; /* Animation effect */
  width: 100%; /* Image will take up the full width of its container */
  object-fit: cover; /* Ensures the image doesn't stretch or squash */
  overflow: hidden;
}

.hover-zoom:hover {
  transform: scale(1.05); /* Zoom in slightly */
}

.overflow-hidden {
  overflow: hidden;
  max-height: 245px;
  margin-bottom: 0.4em;
}

/* Blog */